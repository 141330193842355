import * as React from "react";
import { useNavigate } from "@gatsbyjs/reach-router";

import logo from "../../../images/logo.png";

import { Container, Li } from "./style";

const MenuHorizontal = () => {

  const scrollToElement = (targetId) => {
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Container>
      <div className="header-1">...</div>
      <div className="header-2">
        <div className="div-logo">
          <img className="logo" src={logo} />
        </div>
        <div className="header-menu">
          <Li to="/Home">Beyond the Collectibles...</Li>
          <Li to="/gallery">Gallery</Li>
          {/* <button className="menu-h-btn" onClick={() => scrollToElement('roadmap')}> */}
            <Li to="/Home/#roadmap">Mind map summary</Li>
            {/* Mind map summary */}
          {/* </button> */}
          {/* <button className="menu-h-btn" onClick={() => scrollToElement('theart')}> */}
            <Li to="/Home/#theart">The art</Li>
            {/* The art */}
          {/* </button> */}
          {/* <Li href="/#team" to="">
            Team
          </Li> */}
          {/* <button className="menu-h-btn" onClick={() => scrollToElement('whitelist')}> */}
            <Li to="/Home/#whitelist">Whitelist</Li>
            {/* Whitelist */}
          {/* </button> */}
          {/* <button className="menu-h-btn" onClick={() => scrollToElement('contact')}> */}
            <Li to="/Home/#contact">Contact</Li>
            {/* Contact */}
          {/* </button> */}
          {/* <Li to="">Sign up</Li>
          <Li to="">ign in</Li> */}
        </div>
      </div>
    </Container>
  );
};

export default MenuHorizontal;
