import styled from "styled-components";

export const Container = styled.div`
  max-width: 1440px;
  width: 100%;
  height: 120px;

  margin: auto;

  display: flex;
  flex-direction: column;
  /* position: fixed; */

  margin-bottom: 50px;

  @media all and (max-width: 1024px) {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 500px) {
    margin-bottom: 0;
  }

  .header-1 {
    background-color: #336699;
    color: #336699;
  }

  .header-2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    padding: 25px 0;
  }

  .div-logo {
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo {
    width: 250px;
  }

  .header-menu {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    margin: 20px 40px;
  }
`;

// export const Li = styled(Link)`
//   text-decoration: none;
//   color: #000000;
//   font-family: 'Gloria Hallelujah', cursive;
//   font-weight: 400;
//   font-size: 20px;
//   line-height: 48px;

// `;
