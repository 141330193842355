import * as React from "react";

import { Container } from "./style";

import MenuHorizontal from "./MenuHorizontal";
import MenuVertical from "./MenuVertical";

const HeaderComponent = () => {
  return (
    <Container>
      <MenuHorizontal />
      <MenuVertical />
    </Container>
  );
};

export default HeaderComponent;
