import styled from "styled-components";
import { Link } from "gatsby";

export const Container = styled.div`
  max-width: 1440px;
  width: 100%;
  height: 120px;

  margin: auto;

  display: flex;
  flex-direction: column;

  margin-bottom: 50px;

  .header-1 {
    background-color: #336699;
    color: #336699;
  }

  .header-2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    padding: 25px 0;
  }

  .div-logo {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 12px;
  }

  .logo {
    width: 100%;
    max-width: 170px;
  }

  .header-menu {
    position: absolute;
    width: 0%;
    height: 100%;
    top: 0;
    right: -40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: hidden;
    transition: all 450ms;
    z-index: 1;

    background-color: #fff;
  }

  & button {
     border: none;
     background-color: transparent;
     color: #000000;
     font-family: "Gloria Hallelujah", cursive;
     font-weight: 400;
     font-size: 20px;
     line-height: 48px;
     white-space: nowrap;
     cursor: pointer;
     padding-left: 25px;
    }

  .burguer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    cursor: pointer;

    > span {
      width: 26px;
      height: 3px;
      background-color: #000;
      margin: 3px 30px;

      &:nth-child(1) {
        background-color: #66ffcc;
      }

      &:nth-child(2) {
        background-color: #53d6aa;
      }

      &:nth-child(3) {
        background-color: #336699;
      }
    }
  }

  .x-menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    margin: 20px 20px 20px 0;
    cursor: pointer;

    > span {
      width: 30px;
      height: 3px;
      background-color: #000;
      margin: 15px 40px;

      &:nth-child(1) {
        position: absolute;
        background-color: #66ffcc;
        transform: rotate(42deg);
      }

      &:nth-child(2) {
        background-color: #336699;
        transform: rotate(-42deg);
      }
    }
  }

  @media screen and (min-width: 1025px) {
    display: none;
  }
`;

export const Li = styled(Link)`
  text-decoration: none;
  color: #000000;
  font-family: "Gloria Hallelujah", cursive;
  font-weight: 400;
  font-size: 20px;
  line-height: 48px;
  padding-left: 25px;
`;
