import React, { useState } from "react";

import logo from "../../../images/logo.png";

import { Container, Li } from "./style";

function MenuVertical() {
  const [openMenu, setOpenMenu] = useState(false);

  function handleMenu() {
    setOpenMenu(!openMenu);
  }

  return (
    <Container>
      <div className="header-1">...</div>
      <div className="header-2">
        <div className="div-logo">
          <img className="logo" src={logo} />
        </div>
        <div onClick={() => handleMenu()} className="burguer">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div style={openMenu ? { width: "100%" } : {}} className="header-menu">
          <div onClick={() => handleMenu()} className="x-menu">
            <span></span>
            <span></span>
          </div>
          <Li to="/Home">Beyond the Collectibles...</Li>
          <Li to="/gallery">Gallery</Li>
            <Li to="/Home/#roadmap">Mind map summary</Li>
            <Li to="/Home/#theart">The art</Li>
          {/* <Li href="/#team" to="">
            Team
          </Li> */}
            <Li to="/Home/#whitelist">Whitelist</Li>
            <Li to="/Home/#contact">Contact</Li>
          {/* <Li to="">Sign up</Li>
          <Li to="">ign in</Li> */}
        </div>
      </div>
    </Container>
  );
}

export default MenuVertical;
