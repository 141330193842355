import styled from "styled-components";
import { Link } from "gatsby";

export const Container = styled.div`
  max-width: 1440px;
  width: 100%;
  height: 120px;

  margin: auto;

  display: flex;
  flex-direction: column;

  margin-bottom: 50px;

  .header-1 {
    background-color: #336699;
    color: #336699;
  }

  .header-2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 25px 0;

    @media all and (max-width: 1200px) {
      flex-direction: column;
    }
  }

  .div-logo {
    width: 100%;
    max-width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px 0 12px;
  }

  .logo {
    width: 100%;
    max-width: 250px;
  }

  .header-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    gap: 30px;

    margin: 20px 15px 20px 5px;

    & button {
     border: none;
     background-color: transparent;
     color: #000000;
     font-family: "Gloria Hallelujah", cursive;
     font-weight: 400;
     font-size: 20px;
     line-height: 48px;
     white-space: nowrap;
     cursor: pointer;
    }
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const Li = styled(Link)`
  text-decoration: none;
  color: #000000;
  font-family: "Gloria Hallelujah", cursive;
  font-weight: 400;
  font-size: 20px;
  line-height: 48px;
  white-space: nowrap;
`;
